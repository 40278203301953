import React from "react";
import CTA from "../../components/Cards/CTA";
import Page from "../../components/Page";
import * as styles from "./recension.module.scss"
import data from "../../data/bolag"

export default function Recensioner() {
    const company = "Vattenfall";
    return (
        <>
            <Page title={"Recension av " + company}>
                <div className="container px-5 py-6 mx-auto">
                    <br />
                    <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Recension av {company}</h1>
                    <div className={styles.recension}>
                        <p>Vattenfall är europeiskt energibolag som ägs helt av svenska staten .Företaget är en av de största producenterna av <strong>fossilfri energi</strong> och erbjuder en rad olika elavtal till privatpersoner och företag.</p>
                        <p>Vattenfall har även åtagit sig att påskynda övergången till en fossilfri framtid och erbjuder 100% fossilfri hel från energikällor såsom vattenkraft och kärnkraft.</p>
                        <h2 id="-r-vattenfall-ett-bra-elbolag-">Är Vattenfall ett bra elbolag?</h2>
                        <p>Vattenfall är ett <strong>pålitligt och välrenommerat energibolag</strong> som har åtagit sig att tillhandahålla ren, fossilfri energi. Företagets elavtal är konkurrenskraftigt prissatta och erbjuder en mängd olika alternativ som passar dina behov.</p>
                        <p>Som en av Sveriges största elproducenter erbjuder Vattenfall både avtal med fast och rörlig ränta. Företaget erbjuder också ett blandat avtalsalternativ som ger vinterförsäkring och en blandning av fasta och rörliga räntor.</p>
                        <p>Att bolaget ägs helt av svenska staten kan också ge det en hel del trovärdighet att affärerna sker på ett korrekt sätt. Vattenfall har dessutom en över 100-årig historia inom energibranschen och är en av Europas största elproducenter.</p>
                        <h2 id="vad-kostar-vattenfalls-elavtal-">Vad kostar Vattenfalls elavtal?</h2>
                        <p>Vattenfalls elavtalspriser varierar beroende på vilken typ av avtal du väljer. Företaget erbjuder både avtal med fast och rörlig ränta samt ett blandat alternativ som inkluderar vinterförsäkring.</p>
                        <p>Priserna för Vattenfalls elavtal är konkurrenskraftiga och kan ge mycket valuta för pengarna, beroende på dina behov.</p>
                        <p>Förutom själva elpriset så tillkommer även en kostnad för bolagets påslag och en månadsavgift. Om du väljer rörligt elpris kan du tänkas betala en kostnad enligt nedan:</p>
                        <ul>
                            <li>Rörligt elpris (maj): <strong>130,49 öre/kWh</strong></li>
                            <li>Påslag: <strong>6 öre/kWH</strong></li>
                            <li>Månadsavgift: <strong>45 kronor</strong></li>
                        </ul>
                        <p>Om du istället hade valt ett fast elpris där du binder elpriset i 12 eller 36 månader kommer du betala samma elpris för varje kWh. Om du binder elpriset i 12 månader kan du då tänkas betala:</p>
                        <ul>
                            <li>Fast elpris: <strong>178,63 öre/kWh</strong></li>
                            <li>Månadsavgift: <strong>45 kronor</strong></li>
                        </ul>
                        <h3 id="ska-man-v-lja-ett-r-rligt-eller-fast-elpris-">Ska man välja ett rörligt eller fast elpris?</h3>
                        <p>Valet av elpris är personligt och beror på dina egna omständigheter. Om du letar efter det billigaste alternativet kan ett avtal med rörlig ränta vara det bästa valet.</p>
                        <p>Om du däremot vill ha tryggheten att veta hur mycket din el kommer att kosta varje månad, kan ett avtal med fast ränta vara bättre för dig.</p>
                        <p>Vattenfalls blandade avtalsalternativ kan även erbjuda dig det bästa av två världar, med en blandning av fasta och rörliga priser samt vinterförsäkring.</p>
                        <h2 id="vilka-rabatter-erbjuder-vattenfall-">Vilka rabatter erbjuder Vattenfall?</h2>
                        <p>Som kund hos Vattenfall erbjuder företaget en rabatt på månadsavgiften och en del av elpriset.</p>
                        <p>För ett rörligt elavtal motsvarar rabatten halva årsavgiften och 4,5 öre/kWh av tilläggsavgiften under 12 månader, och för ett fast elavtal motsvarar rabatten halva årsavgiften och 4 öre på elpriset.</p>
                        <p>Rabatten fördelas över 12 månader för det rörliga elavtalet och över hela bindningstiden för de fasta elavtalen.</p>
                        <h2 id="vad-r-vattenfalls-kundn-jdhet-">Vad är Vattenfalls kundnöjdhet?</h2>
                        <p>Vattenfall har ett kundnöjdhetsbetyg på 2,1/5, enligt Trustpilot.</p>
                        <p>Företaget har ett blandat rykte, där vissa kunder berömmer företaget för dess konkurrenskraftiga priser och utmärkta kundservice, medan andra har klagat på höga priser, kundservice och faktureringsproblem.</p>
                        <p>Om du funderar på att teckna ett elavtal med Vattenfall är det viktigt att du gör din egen research för att försäkra dig om att företaget är rätt val för dig.</p>
                        <p>Du kan också jämföra med andra elbolag på marknaden för att se till att du får det bästa erbjudandet.</p>

                    </div>
                </div>
            </Page>
        </>
    );
}
